.contratar-plano .card-planos {
    width: 100%;
    height: 480px;
    background: white;
    padding: 2.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 6px;
    box-shadow: 0px 0px 6px 0px #e2e2e2;
    margin-bottom: 2rem;
    margin-top: 0rem;
    border-top-right-radius: 0px;
    padding-top: 1.5rem;
    border-top-left-radius: 0px;
}
.contratar-plano {
    margin-top: 4rem;
    height: 89vh;

}
.contratar-plano .MuiGrid-root.MuiGrid-container{
    height: 89vh;
}
.contratar-plano .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-9{
    height: 89vh;
}
.contratar-plano .MuiPaper-root.MuiPaper-rounded{
    height: 100%;
    box-shadow: 0px 0px 0px 0px gray;
}
.contratar-plano .envolve-todos-cards{
    height: 87vh;
}
.btn-selecionar-plano.contratar-plano-btn{
    background-color: #aaaaaa; 
    width: 100%;
}
.btn-selecionar-plano.contratar-plano-btn:hover{
    background-color: #aaaaaa; 
}
.contratar-plano .envolve-titulo-card-planos h2{
    color: #27b8d2;
}
.contratar-plano .envolve-titulo-card-planos p{
    color: #27b8d2;
}
.contratar-plano .envolve-titulo-card-planos p span{
    color: #27b8d2;
}
.contratar-plano .envolve-titulo-card-planos{
    border-bottom: 0px solid #27b8d2;
}
.plano-escolhido-contratar{
    height: 7vh;
    background-color: #e7366a;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    font-size: 1.1em;
    margin-top: 2rem;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    box-shadow: 0px 0px 6px 0px #e2e2e2;
}
.contratar-plano .envolve-periodo-card-plan {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #27b8d2;
    border-radius: 30px;
    width: 100%;
}
.contratar-plano .envolve-periodo-card {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 1.5rem;
    margin-top: 1.5rem;
}
.dados-cartao-contratar-plano{
    margin-right: 1rem;
    margin-left: 1rem;
    margin-bottom: 4rem;
}
.dados-cartao-contratar-plano h1, .dados-cartao-contratar-plano h2 {
    color: #8f8f8f;
}
.p-contratar-plano{
    margin-top: 3rem;
}
.contratar-plano .envolve-periodo-card{
    margin: 0;
    margin-bottom: 1.5rem;
}
.rccs__card{
    margin-bottom: 2rem !important;
}
.contratar-plano-card{
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
}
.rccs{
    margin: 0 !important;
}
.contratar-plano .MuiFormControl-root{
    width: 100%;
}
.widt-card-plan-escolhido{
    width: 65%;
}
.btn-footer-contratar-plan button{
    margin: 1rem;
}
.btn-footer-contratar-plan{
    display: flex;
    width: 92%;
    align-items: center;
    justify-content: flex-end;
}
.usar-cupon-btn{
    padding-right: 0;
}
.usar-cupon-btn button{
    height: 55px;
    width: 17ch;
    border-radius: 0;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}
.contratarPlano{
    padding: 1rem;
    padding-bottom: 3rem;
}
.div-btn-pagar{
    display: flex;
    justify-content: flex-end;
    width: 100%;
}
.alert-pagamento{
    margin-bottom: 1rem;
    width: 90%;
}