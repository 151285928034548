.container-tabela {
    margin-top: 30px;
    padding-bottom: 10px;
   
}
.MuiGrid-root.table-div-scroll{
    height: 100vh;
    overflow: auto;
}
.e-control{
    min-height: 500px;
}