.ListagemModelo-container .MuiGrid-root.MuiGrid-container{
    min-height: 90vh;
    padding-top: 4rem !important;
    height: 100%;
}
.ListgemModelo-prontuarios.atendimento{
    padding-top: 0rem;
    margin-left: 1rem;
    margin-right: 1rem;
    background-color: white;
    padding: 1rem;
    max-height: 74vh;
    margin-top: 0rem;
    height: 100%;
}
.ListgemModelo-prontuarios .MuiButtonBase-root.MuiTab-root.MuiTab-textColorInherit.Mui-selected{
    color: #27b8d2;
}
.ListgemModelo-prontuarios h1{
    color: #8F8F8F;
    font-family: 'Roboto',Arial, Helvetica, sans-serif;
    font-weight: 500;
    margin-bottom: 0.5;
}
.CardModelos-envolve{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #dedede;
}
.CardModelos-envolve-text-icon{
    display: flex;
   align-items: center; 
   justify-content: flex-start;
  

}
.CardModelos-icon-pront{
    margin-right: .5rem;
    color: #27b8d2;
}
.CardModelos-envolve-text-icon p{
    color: #8F8F8F;
}
.CardModelos-envolve .MuiIconButton-root{
    color: #8F8F8F;
}
.ListagemModelo-prontuario-name-button{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 24px;
    padding-right: 24px;
}
.padding-lateral{
    padding-right: 0rem !important;
    padding-left: 0rem !important;
}
.retirar-padding-lateral .MuiBox-root{
    padding-right: 0px;
    padding-left: 0px;
}
.Color-btn-addModelo{
    background-color: #27b8d2 !important;
    color: white !important;
}
.div-load-lista-modelos{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 10vh;
}
.div-load-lista-modelos svg{
    color: #27b8d2 !important;
}
.app-bar-modelos-pront .MuiAppBar-colorPrimary{
    background-color: white;
}
.CardModelos-envolve-mobile{
    display: flex;
    align-items: center;
    min-width: 100%;
}