form .mb-4{
    margin-bottom: 1.5rem;
}
div .titulo-form{
    color: #27b8d2;
    font-size: 1.1em;
    font-weight: 500;
    margin-top: 0;
}
.div-checkbox-margin{
    margin-left: 2rem;
    margin-bottom: 2rem;
}
.img-input{
    width: 100%;
    height: auto;
    justify-content: center;
    align-items: center;
    border: 1px solid #f7f7f7;
    box-shadow: 0px 0px 5px 0px #eaeaea;
}
.img-input img{
    min-width: 100%;
    max-width: 100%;
    height: auto;
}
.envolve-inputImagem, .envolve-inputTabela, .envolve-inputEscala{
    margin: 2rem;
    margin-bottom: 3rem;
    margin-top: 0;
}
.MuiSlider-track, .MuiSlider-rail{
    height: 5px !important;
}
.MuiSlider-markLabel.MuiSlider-markLabelActive,.MuiSlider-markLabel{
    margin-top: .25rem !important;
    font-weight: 500 !important;
    font-size: .9em !important;
    color: #4e4e4e !important;
}