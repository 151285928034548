.container-envolve-planos{
    margin-top: 5rem;
    margin-left: 1rem;
    margin-right: 1rem;
    margin-bottom: 4rem;
}
.titulo-pag-planos{
    text-align: center;
    color: #8F8F8F;
}
.titulo-pag-planos h1{
   margin-bottom: 0;
   font-size: 1.7em;
}
.titulo-pag-planos p{
    font-size: 1.3m;
 }
 .card-planos{
    width: 100%;
    height: 500px;
    background: white;
    padding: 2.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 6px;
    box-shadow: 0px 0px 6px 0px #e2e2e2;
    margin-bottom: 2rem;
 }
 .envolve-titulo-card-planos{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 1rem;
    border-bottom: 1px solid #c7c7c7;
    margin-bottom: .4rem;
}
.envolve-titulo-card-planos p, .envolve-titulo-card-planos h2{
    margin: 0;
}
.envolve-titulo-card-planos p{
    margin-bottom: .3rem;
    color: #8f8f8f;
}
.envolve-titulo-card-planos p span.preco-plano{
    font-size: 3.5em;
    font-weight: 700;
    color: #8f8f8f;
    margin-right: .3rem;
}
.envolve-titulo-card-planos p span.preco-moeda{    
    color: #8f8f8f;
    font-size: 1.7em;
    font-weight: 500;
    margin-right: .4rem;
}
.envolve-titulo-card-planos p span.preco-periodo{
    color: #8f8f8f;
    font-weight: 400;
    font-size: 1.7em;
}
.envolve-titulo-card-planos h2{
    color: #8f8f8f;
}
.btn-selecionar-plano{
    width: 87%;
    margin-top: .5rem !important;
}
.ul-list-descricao{
    padding-left: 0rem;
    list-style-type: none;
}
.div-ul-list-descricao{
    width: 100%;
}
.div-ul-list-descricao ul li{
    margin-bottom: .7rem;
    color: #8f8f8f;
}
.envolve-todos-cards{
    display: flex;
    justify-content: center;
}
.envolve-periodo-card{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 1.5rem;
    margin-top: 1.5rem;
}
.envolve-periodo-card-plan{
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #27b8d2;
    border-radius: 30px;
    width: 100%;
}
.envolve-periodo-card-plan-active{
    background-color: #27b8d2;
    color: white;
    border-radius: 30px;
    width: 50%;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: .9em;
    padding-right: 2rem;
    padding-left: 2rem;
}
.envolve-periodo-card-plan-desactive{
    color: #27b8d2;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: .9em;
    cursor: pointer;
    padding-right: 2rem;
    padding-left: 2rem;
}
.envolve-periodo-card-plan-desactive p, .envolve-periodo-card-plan-active p{
  margin: .5rem;
}