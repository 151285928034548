.envolveBtnsMenu{
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.p-menu{
    margin: 0;
    font-size: .5em;
    margin-bottom: .1rem;
}
.icon-menu{
    width: .9em !important;
    height: .9em !important;
    margin-bottom: .2rem;
}
.envolveBtnsMenu .MuiIconButton-label {
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.active .icon-menu, .active .p-menu{
    color: #27b8d2;
}
.MuiToolbar-gutters{
    padding: 0 !important;
}
@media only screen and (max-width: 340px) {
    .divappbar .MuiPaper-root.MuiAppBar-root.MuiAppBar-positionFixed.MuiAppBar-colorPrimary {
        padding-right: 0rem;
        padding-left: 2.5rem;
    }
}