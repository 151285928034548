.Dashboard-container .MuiGrid-root.MuiGrid-container{
    height: 90vh;
}
.Dashboard-Graficos-alert .MuiAlert-icon{
    display: none;
}
.Dashboard-Graficos-alert.MuiAlert-filledInfo {
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        background-color: #27b8d2;
        min-width: 13vh;
        height: 100%;
      
}
.Dashboard-Graficos-container{
    padding: 1rem;
    height: 100%;
    margin-top: 4rem;
}
.Dashboard-Graficos-alert.MuiAlert-filledInfo button{
    margin-left: 1rem;
}
.Dashboard-Graficos-alert .MuiAlert-message {
    padding: 4px 0;
}
.Dashboard-Graficos-p-select{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-left: .25rem;
    margin-right: 1rem;
    margin-top: 1rem;
}
.d-flex-f{
    display: flex;
    align-items: center;
    width: 100%;
}
.d-flex-f p{
    margin-top: 0;
}
.Dashboard-Graficos-p-select.profissionais{
    width: 50%;
}
.Dashboard-Graficos-p-select p{
    font-weight: 600;
    color: #8f8f8f;
    margin-right: .25rem;
}
.Dashboard-Graficos-select{
    background-color: white;
    width: 100%;
    margin: 0 !important;
    margin-bottom: 1rem !important;
}

.btn-margin-rigth{
    width: 100%;
    margin: 0;
    margin-right: 0;
    margin-bottom: 1rem !important;
}
.Dashboard-Graficos-select input{
    padding: 10px !important;
}
.Dashboard-Graficos-select .MuiInputLabel-formControl {
    top: -.5rem;
}
.MuiInputLabel-root.Mui-focused{
    top: -.1rem;
}
.Dashboard-Graficos-select .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiInputLabel-outlined.MuiFormLabel-filled{
    top: 0rem;
}

.Dashboard-Graficos-selects{
    display: flex;
    justify-content: start;
    align-items: center;
}
.Dashboard-Graficos-selects .MuiOutlinedInput-notchedOutline {
    border-color: rgb(0 0 0 / 0%);
    box-shadow: 0px 0px 3px 0px #cecece;
}  
.Dashboard-Graficos-info-pacientes{
    display: flex;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    background-color: white;
    padding: 1rem;
    border-radius: 10px;
    box-shadow: 0px 0px 4px -2px #b9b9b9;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
    height: 24vh;
}
.Dashboard-Graficos-numero-pacientes{
    font-size: 2em;
    font-weight: 600;
    margin: 0;
}
.Dashboard-Graficos-text-pacientes{
    margin: 0;
    font-size: 1em;
    font-weight: 400;
}
.Dashboard-Graficos-icon-pacientes{
    width: 1.6rem !important;
    height: 1.6rem !important;
    margin-top: .4rem;
}
.Dashboard-Graficos-cards-graficos{
    width: 100%;
    height: 45vh;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 0px 3px 0px gainsboro;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: .1rem;
}
.Dashboard-Graficos-cards{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;
    width: 100%;
    height: 100%;
}
.Dashboard-Graficos-cards-graficos-title{
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 0;
    margin-left: 2rem;
    font-weight: 500;
    color: #27b8d2;
    font-size: 1.1em;
}
.Dashboard-Graficos-cards-graficos-legendas-circle{
    width: 12px;
    height: 12px;
    border-radius: 50%;
}

.Dashboard-Graficos-cards-graficos-legendas-circle.recorrentes{
    background-color: #e7366a;
}
.Dashboard-Graficos-cards-graficos-legendas-circle.novos{
    background-color: #27b8d2;
}
.Dashboard-Graficos-cards-graficos-legendas-title{
    font-weight: 400;
    color: #8f8f8f;
    font-size: 1em;
    margin: 0;
    margin-left: .25rem;
}
.Dashboard-Graficos-cards-graficos-legendas{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: .5rem;
    width: 100%;
}
.Dashboard-Graficos-div-scroll{
    height: 100%;
    overflow-x: hidden;
}
.d-flex-dash{
    display: flex;
}
.atendimento-periodo{
    width: 100%;
    height:50vh;
    margin-top: 1rem;
}
.distribuicao-etaria{
    width: 100%;
    margin-top: 1.5rem;
}
.distribuicao-etaria p, .atendimento-periodo p{
    margin-bottom: .5rem;
}
.aniversariantes{
    width: 100%;
    margin-top: 1.5rem;
    margin-bottom: 6rem;
}
.Dashboard-Graficos-info-pacientes-faltaram, .Dashboard-Graficos-info-pacientes-agendados{
    width: 50%;
}
.min-atendimento{
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5em;
    font-weight: 500;
    color: #8f8f8f;
    margin: 0;
    margin-top: .4rem;
}
.title-duracao-agendamento{
    margin-left: 1rem;
    width: 100%;
}
.Dashboard-Graficos-cards-graficos-duracao-agendamento{
    width: 100%;
}
.aniversariantes-dashboard{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 40vh;

}
.aniversariantes-dashboard p{
    font-weight: 500;
    font-size: 1em;
    color: #8f8f8f;
    margin-top: 1rem;
}
.aniversariantes-dashboard button{
    margin-top: 1.5rem;
}
.modal-aniversariante{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e2e2e2;
    margin: 1rem;
}
.modal-aniversariante-img-p{
    display: flex;
    justify-content: center;
    align-items: center;
}
.margin-rigth-p{
    margin-right: 1rem;
}
.nao-possui-dados{
    height: 40vh;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #8f8f8f;
    font-weight: 500;
}
.graf-pac .recharts-responsive-container{
    height: 30vh !important;
}
.nao-possui-dados-perio{
    height: 113vh;
    color: #8f8f8f;
    font-weight: 500;
    width: 100%;
    margin-left: 2rem;
    margin-top: 1rem;
}

.header-modal{
    width: 100%;
    height: 80px;
    background-color: #cec6c6;
    display: flex;
    justify-content: space-between;
    padding: 20px;
}
.header-modal div{
    display: flex;
    flex-direction: column;
}
.header-close-icon{
    cursor: pointer;
}
.header-close-icon:hover{
    color: red;
}
.header-publication-date{
    color: #e7366a;
    font-weight: 500;
}
.header-update{
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
}


.header-update-type{
    padding: 5px 20px;
    background-color: #e2e2e2;
}
.header-update-content{
    padding: 0 20px 20px 20px;
}



.header-tutorial{
    width: 100%;
    height: 100px;
    background-color: #27b8d2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
}
.header-tutorial-title{
    font-weight: 500;
}
.header-body{
    display: flex;
    justify-content: center;
}
.video-container{
    padding: 20px;
    width: 100%;
}
.desc-tutorial{
    display: flex;
    padding: 20px;
    background-color: rgba(39, 184, 210, 0.6);
    font-weight: 500;
}