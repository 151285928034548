body{
    margin: 0;
    padding: 0;
}
::-webkit-scrollbar-track {
    background-color: #F4F4F4;
}
::-webkit-scrollbar {
    width: 8px;
    background: #F4F4F4;
}
::-webkit-scrollbar-thumb {
    background: #b9b8b8;
}
.MuiPaper-root.MuiAppBar-root.MuiAppBar-positionFixed.MuiAppBar-colorPrimary{
    padding-right: 1rem;
    padding-left: 1rem;
    padding-top: .1rem;
}
.color-secondary{
    background-color: #f50057 !important;
    color: white !important;
}
.color-secondary:hover{
    background-color: #c51162 !important;
}
.color-secondaryD{
    background-color: #FFFFFF !important;
    color: #8F8F8F !important;
}
.color-secondaryD:hover{
    background-color: #fafafa !important;
}
.color-primary{
    background-color: #27b8d2 !important;
    color: white !important;
}
.color-primary:hover{
    background-color: #24acc4 !important;
}   
.color-disable{
    cursor: auto !important;
    background-color: #b6b6b6 !important;
    color: #ececec !important;
} 

.MuiPaper-root.MuiAlert-root.MuiAlert-filledInfo.Dashboard-Graficos-alert .MuiAlert-message{
    text-align: center;
}
.MuiPaper-root.MuiAlert-root.MuiAlert-filledInfo.Dashboard-Graficos-alert .MuiAlert-message button{
    margin-top: .5rem;
}
.Modal-form-add-agendamento-btn-add-proc{
    min-width: 100% !important;
    min-height: 35px !important;
}

.container {
    margin: 6rem 1.5rem!important;
}

.containerAlert {
    margin: 1rem 1.5rem!important;
}

.containerClinic {
    margin: 10rem 1.5rem!important;
}

.containerClinicAlert {
    margin: 14rem 1.5rem!important;
}